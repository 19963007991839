import { toast as Vue3Toastify } from 'vue3-toastify'
import { useRequestCounter } from '@shared/composables/useRequestCounter'
import type { LeaseDocumentValue } from '@ui/formkit/inputs/leaseDocumentInput/types'
import type {
  UseSubmitHandlerOptions,
  UseSubmitHandlerRequest,
  UseSubmitHandlerReturn,
} from '@shared/composables/useSubmitHandler'

export function useFieldSubmitHandler<D = unknown, R = unknown>(
  key: string,
  request: UseSubmitHandlerRequest<D, R>,
  options: UseSubmitHandlerOptions<D, R> = {},
): UseSubmitHandlerReturn<D, R> {
  // REQUEST COUNTER
  const { increment, decrement } = useRequestCounter(key)

  return useSubmitHandler<D, R>(request, {
    ...options,
    toastOptions: {
      position: Vue3Toastify.POSITION.BOTTOM_RIGHT,
    },
    loadingMessage: false,
    successMessage: false,
    errorMessage: options.errorMessage ?? 'Failed to save',
    beforeSubmit: async (context) => {
      const _beforeSubmit = await options.beforeSubmit?.(context)

      if (_beforeSubmit === false) {
        return false
      }

      // Prevent double submit:
      // 1. if previous submit failed and the approved flag was reset.
      // 2. syncing after approved.
      // 3. syncing affected fields.
      if (context.node?.context.preventNextRequest) {
        context.node.context.preventNextRequest = false
        return false
      }

      // Increment request counter
      increment()
    },
    onError: (_, { node }) => {
      // Reset approved
      if (node?.context) {
        const originalApproved = node.context.field.value?.isApproved ?? false
        const currentApproved = node.context.isApproved

        if (currentApproved === true && originalApproved === false) {
          node.context.preventNextRequest = true
          node.reset({
            ...(node._value as LeaseDocumentValue),
            isApproved: false,
          })
        }
      }
    },
    onDisplayFormkitError: (data, { node }) => {
      if (data.detail) {
        node?.setErrors([data.detail])
      }
    },
    onFinally: () => {
      // Decrement request counter
      decrement()
    },
  })
}
